import axios from "@axios";
import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDocuments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/document/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/document`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addDocument(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/document', data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
            )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    updateDocument(ctx, data) {
      return new Promise((resolve, reject) => {
          axiosIns.put('/document', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  },
    deleteDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/document", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
