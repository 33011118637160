<template>
<b-modal
  id="modal-send-email"
  ok-title="Submit"
  scrollable
  hide-footer
  no-close-on-backdrop
  size="lg"
  hide-header
>
  <feather-icon @click="hide" style="
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;" size="18" icon="XIcon" />
  <email-page :document="emailDocument"></email-page>
</b-modal>

</template>

<script>
import emailPage from '@/views/email/email.vue'
import {
  BModal,
  VBModal,
 
} from "bootstrap-vue";

export default{
    components:{
        emailPage,
        BModal,
        VBModal,
    },
    methods:{
        hide(){
            this.$bvModal.hide("modal-send-email");
        },
        onSubmit(){
        }
    },
    props:[
       'emailDocument'    
    ],
    data(){
        return{
            loading:false,
          
        }
    }
}

</script>

<style lang="scss">

</style>
