<template>
  <b-modal
    id="modal-user"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title="CHANGE AUTHORISED USER"
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-footer
    no-close-on-backdrop
    size="lg"
    hide-header-close
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-sm-1" @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label="Authorised User"
              label-for="authorised_users"
              label-cols-md="3"
            >
              <div class="form-col-select">
                <v-select
                  id="authorised_users"
                  v-model="authorised_users"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(option) => option.id"
                  multiple
                  placeholder="Select Staff"
                  @input="(val) => handleUserSelection(val)"
                />

                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
            Submit
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCardBody,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    vSelect,
    BCard,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    trigger(newVal, oldVal) {
      // this.authorised_users = this.documentData.authorised_users.map((user) => user.id);
      if (this.documentData.all_bit == 1) {
        // this.authorised_users = [0];
        this.userOptions
          .filter((x) => x.id != 0)
          .forEach((user) => {
            return this.authorised_users.push(user.id);
          });
        if (this.userOptions.length - 1 != this.authorised_users.length) {
          this.all_bit = 0;
        } else {
          this.all_bit = 1;
        }
      } else {
        this.authorised_users = this.documentData.authorised_users.map(
          (user) => user.id
        );
        this.all_bit = 0;
      }
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-user");
      this.authorised_users = [];
    },
    handleUserSelection(val) {
      if (this.authorised_users.includes(0)) {
        this.authorised_users.length = 0;
        this.userOptions
          .filter((x) => x.id != 0)
          .forEach((user) => {
            return this.authorised_users.push(user.id);
          });
        this.all_bit = 1;
      } else {
        this.all_bit = 0;
      }
    },
    // handleUserSelection(val) {
    //   console.log(val);

    //   if (val.includes(0)) {
    //     console.log("is all");
    //   } else {
    //     console.log("is not all");
    //   }

    //   // if (val.includes(0)) {
    //   //   // this.authorised_users = [0];
    //   //   // if (this.authorised_users == [0]) {
    //   //   console.log(this.authorised_users);
    //   //   this.userOptions.forEach((user) => {
    //   //     return this.authorised_users.push(user.id);
    //   //   });
    //   //   const index = this.authorised_users.indexOf(0);
    //   //   this.authorised_users.slice(index + 1);
    //   //   // this.userOptions.map((user) => {
    //   //   //   return this.authorised_users.push(user.id);
    //   //   // });
    //   //   // this.authorised_users.slice(1);
    //   //   // this.authorised_users.slice(1);
    //   //   console.log(this.authorised_users);
    //   //   // }
    //   //   this.all_bit = 0;
    //   // }
    // },
    onSubmit() {
      console.log(this.authorised_users);
      console.log(this.all_bit);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // this.handleUserSelection();
          let submitData = {
            id: this.documentData.id,
            all_bit: this.all_bit,
            authorised_users: this.authorised_users,
          };
          this.loading = true;
          store
            .dispatch("app-document/updateDocument", submitData)
            .then((response) => {
              this.loading = false;
              this.$emit("refetch-data");
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    documentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      authorised_users: [],
      all_bit: 0,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
